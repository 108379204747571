import * as React from 'react'
import Container from './container'
import { GatsbyImage } from 'gatsby-plugin-image'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import Link from './link'

const Testimonial = ({ data, stripe, link, ...props }) => {
  const isStripe = stripe && !data.image

  return (
    <div
      className={`Section ${isStripe ? 'Section--stripe' : 'Section--dark'} text-center`}
      {...props}>
      {data.image && <>
        <GatsbyImage
          image={data.image.gatsbyImageData}
          alt=''
          className='absolute inset-0'
        />
        <div className='bg-darken5 absolute inset-0' />
      </>}
      <Container className='relative'>
        <div
          className={`font-serif font-semi-bold italic leading-none ${isStripe || 'text-gray'}`}
          style={{ fontSize: isStripe ? '6rem' : '12rem' }}>
          ”
        </div>
        <div className='Testimonial'>
          {data.quote.childMdx?.body &&
            <MDXRenderer children={data.quote.childMdx.body} />
          }
        </div>
        {(data.name || data.title || link) &&
          <div className='mt-3'>
            {data.name &&
              <div className='sm:text-md'>
                {data.name}
              </div>
            }
            {data.title &&
              <div className='font-semi-bold sm:text-md'>
                {data.title}
              </div>
            }
            {link &&
              <div className='mt-3'>
                <Link
                  href={link.url}
                  className='uppercase font-semi-bold underline'
                  variant='muted'
                  children={link.text}
                />
              </div>
            }
          </div>
        }
      </Container>
    </div>
  )
}

export default Testimonial

import * as React from 'react'
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import { graphql, Link as GatsbyLink } from 'gatsby'
import { Helmet } from 'react-helmet'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import VideoModal from '../../components/video_modal'
import Button from '../../components/button'
import Link from '../../components/link'
import Icon from '../../components/icon'
import Box from '../../components/box'
import Subnav from '../../components/subnav'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import Container from '../../components/container'
import Testimonial from '../../components/testimonial'
import DemoForm from '../../components/demo_form'
import Anchor from '../../components/anchor'

const StoryPage = ({ data }) => {
  
  const story = data.contentfulStory
  const hasSubNav = Boolean(story.challengeText || story.solutionsText)
  
  return (
    <Layout activeNav={true}>
      <Helmet>
        <meta property='og:image' content={story.openGraphImage.file.url} />
        <meta property='og:image:width' content={600} />
        <meta property='og:image:height' content={314} />
      </Helmet>
      <Seo
        title={`Customer Story | Roadster - ${story.name}`}
        excludeTitleSuffix={true}
        description={story.metaDescription}
      />
      <Box
        css={{
          height: 540,
          '@sm': {
            height: '70vh',
            minHeight: 580
          }
        }}
        className='bg-black flex flex-col justify-center relative text-white'
      >
        <GatsbyImage
          image={story.heroBackground.gatsbyImageData}
          alt=''
          className='absolute inset-0'
        />
        <Container className='text-center sm:text-left relative'>
          <div className='sm:flex'>
            <div className='shrink-0 xs:my-3'>
              <GatsbyImage
                image={story.avatar.gatsbyImageData}
                alt=''
                className='rounded-full overflow-hidden'
                imgClassName='rounded-full border-2 border-white'
              />
            </div>
            <div className='flex-1 text-base sm:ml-3'>
              {story.storyType}
              <h1 className='sm:text-3xl lg:text-4vw font-semi-bold mt-0'>
                {story.name}
              </h1>
              <div className='flex items-center justify-center sm:justify-start mt-3'>
                <Button
                  href='start'
                  variant='light'
                  onClick={(e) => {
                    e.preventDefault()
                    document.getElementById('start').scrollIntoView({
                      behavior: 'smooth'
                    })
                  }}
                  children='Read Story'
                />
                {story.video?.videoId &&
                  <VideoModal videoId={story.video.videoId}>
                    <Link className='ml-3 text-md font-semi-bold'>
                      <Icon
                        name='play'
                        className='mr-1/2em align-middle'
                        style={{ width: '2em', height: '2em' }}
                      />
                      Watch Video
                    </Link>
                  </VideoModal>
                }
              </div>
            </div>
          </div>
        </Container>
      </Box>
      
      {hasSubNav &&
        <Subnav
          isSticky={true}
          links={[
            story.challengeText && {
              href: '#challenge',
              text: 'Challenge'
            },
            story.solutionsText && {
              href: '#solution',
              text: 'Solution'
            },
            story.testimonial && {
              href: '#testimonial',
              text: 'Testimonial'
            },
            story.results && {
              href: '#results',
              text: 'Results'
            }
          ]}
        />
      }

      {story.overviewText &&
        <div className='Section Section--stripe text-center'>
          <Container>
            <div className='text-md sm:text-lg leading-relaxed mx-auto' style={{ maxWidth: '46em' }}>
              {renderRichText(story.overviewText)}
            </div>
            {story.url &&
              <div className='mt-4'>
                <Link
                  href={story.url}
                  rel='noopener noreferrer'
                  target='_blank'
                  className='font-semi-bold uppercase underline'
                  variant='muted'
                  children={`Visit ${story.name}`}
                />
              </div>
            }
          </Container>
        </div>
      }

      <Anchor id='start' />

      {hasSubNav && <>

        {story.challengeText &&
          <div className='Section' id='challenge'>
            <Container size='lg'>
              <h2 className='text-xl sm:text-2xl font-light mt-0'>Challenge</h2>
              <div className='text-base sm:text-md leading-relaxed' style={{ maxWidth: '56em' }}>
                {renderRichText(story.challengeText)}
              </div>
            </Container>
          </div>
        }

        {story.solutionsText &&
          <div className='Section Section--stripe' id='solution'>
            <Container size='lg'>
              <div className='grid md:grid-cols-2 gap-3 md:gap-0'>
                <div>
                  <h2 className='text-xl sm:text-2xl font-light mt-0'>Solutions</h2>
                  <div className='text-base sm:text-md leading-relaxed' style={{ maxWidth: '56em' }}>
                    {renderRichText(story.solutionsText)}
                  </div>
                </div>
                <div>
                  {story.solutionsImage &&
                    <GatsbyImage
                      image={story.solutionsImage.gatsbyImageData}
                      alt=''
                    />
                  }
                </div>
              </div>
            </Container>
          </div>
        }

        {story.testimonial &&
          <Testimonial
            id='testimonial'
            data={story.testimonial} />
        }

        {story.results &&
          <div className='Section Section--stripe' id='results'>
            <Container>
              <h2 className='text-xl sm:text-2xl font-light text-center mt-0 mb-4 md:mb-5'>Results</h2>
              <div className='flex flex-wrap text-center -m-3 justify-center'>
                {story.results.map(result =>
                  <div
                    key={`case_study_result_${result.title}`}
                    className='w-full sm:w-1/2 lg:w-1/3 p-3'
                  >
                    <h3 className='uppercase text-md md:text-lg tracking-wide'>
                      {result.title}
                    </h3>
                    <hr />
                    {result.extraData &&
                      <div className='shrink-0 font-semi-bold tracking-tight leading-none' style={{ fontSize: '5.5em' }}>
                        {result.extraData}
                      </div>
                    }
                    <div className='leading-relaxed text-md mt-1/2em'>
                      {renderRichText(result.text)}
                    </div>
                  </div>
                )}
              </div>
            </Container>
            {story.caseStudyPdf &&
                <div className='text-center mt-4 sm:mt-5'>
                  <Button
                    href={story.caseStudyPdf}
                    variant='dark'
                    children='Download Case Study'
                  />
                </div>
              }
          </div>
        }
      
      </>}

      {(!hasSubNav && story.testimonial) &&
        <Testimonial
          data={story.testimonial}
          stripe={true}
          link={{
            url: story.url,
            text: `Visit ${story.name}`
          }}
        />
      }
      
      {story.usage &&
        <div className='Section'>
          <Container>
            <h2 className='text-2xl lg:text-3xl text-center font-semi-bold mt-0 mb-4 md:mb-5'>
              How {story.name} Uses Roadster
            </h2>
            <div className='grid gap-4 md:grid-cols-2'>
              <div className='grid gap-x-3 gap-y-4 sm:gap-x-4 grid-cols-2 sm:grid-cols-3 md:grid-cols-2'>
                {story.usage.map((usage, usageIndex) =>
                  <div key={`story_usage_${usageIndex}`}>
                    <div className='CircleNum'>
                      {usageIndex + 1}
                    </div>
                    <div className='mt-2 text-base sm:text-md leading-relaxed'>
                      {renderRichText(usage.text)}
                    </div>
                  </div>
                )}
              </div>
              <div>
                {story.usageImage &&
                  <GatsbyImage
                    image={story.usageImage.gatsbyImageData}
                    alt=''
                  />
                }
              </div>
            </div>
          </Container>
        </div>
      }

      {(story.video?.videoId || story.video?.poster) &&
        <div className={`Section ${story.usage ? 'pt-0' : ''}`}>
          <Container size='lg'>
            {story.video.title &&
              <h2 className='text-xl sm:text-2xl font-light text-center mt-0 mb-3 sm:mb-4'>
                {story.video.title}
              </h2>
            }
            <VideoModal videoId={story.video.videoId}>
              <button type='button' className='block relative w-full'>
                <GatsbyImage
                  image={story.video.poster.gatsbyImageData}
                  alt=''
                />
                <StaticImage
                  alt='Play Video'
                  src='../../images/icon-play.png'
                  className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 hover:opacity-60 transition-opacity duration-300'
                  placeholder='tracedSVG'
                  style={{ width: '12%' }}
                />
              </button>
            </VideoModal>
          </Container>
        </div>
      }

      {(story.video2?.videoId || story.video2?.poster) &&
        <div className={`Section Section--stripe ${story.video2 ? 'pb-0' : ''}`}>
          <Container size='lg'>
            {story.video2.title &&
              <h2 className='text-xl sm:text-2xl font-light text-center mt-0 mb-3 sm:mb-4'>
                {story.video2.title}
              </h2>
            }
            <VideoModal videoId={story.video2.videoId}>
              <button type='button' className='block relative w-full'>
                <GatsbyImage
                  image={story.video2.poster.gatsbyImageData}
                  alt=''
                />
                <StaticImage
                  alt='Play Video'
                  src='../../images/icon-play.png'
                  className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 hover:opacity-60 transition-opacity duration-300'
                  placeholder='tracedSVG'
                  style={{ width: '12%' }}
                />
              </button>
            </VideoModal>
          </Container>
        </div>
      }

      {story.testimonial2 &&
        <Testimonial
          data={story.testimonial2}
          stripe={true}
        />
      }

      <div className='Section Section--dark'>
        <Container>
          <DemoForm invert={true} />
        </Container>
      </div>

      {story.relatedStories &&
        <div className='Section Section--stripe'>
          <Container size='lg'>
            <h2 className='mt-0 text-center text-xl sm:text-2xl lg:text-3xl font-light sm:mb-4'>
              More Customer Stories
            </h2>
            <div className='grid gap-3 sm:grid-cols-2 md:grid-cols-3 lg:gap-4'>
              {story.relatedStories.map(relatedStory =>
                <GatsbyLink
                  key={`story_link_${relatedStory.slug}`}
                  to={`/customer_stories/${relatedStory.slug}`}
                  className='flex flex-col bg-white rounded-md p-3 hover:shadow-lg transition-shadow border border-darken1 group'
                >
                  <div>
                    <div className='text-gray text-base font-semi-bold'>
                      {relatedStory.storyType}
                    </div>
                    <h3 className='text-md mt-1'>
                      {relatedStory.name}
                    </h3>
                    <div className='my-3'>
                      <GatsbyImage
                        image={relatedStory.avatar.gatsbyImageData}
                        alt=''
                        className='rounded-full overflow-hidden'
                        imgClassName='rounded-full'
                      />
                    </div>
                    <Box
                      css={{ '& b': { fontWeight: '$semi-bold' } }}
                      className='leading-relaxed text-md'>
                      {relatedStory.blurb}
                    </Box>
                  </div>
                  <div className='mt-auto pt-4'>
                    <Link
                      variant='muted'
                      arrowDirection='right'
                      className='group-hover:opacity-100 uppercase font-semi-bold tracking-wide'
                      children='Read Story'
                    />
                  </div>
                </GatsbyLink>
              )}
            </div>
            <div className='mt-4 text-center'>
              <Button
                variant='outline'
                minWidth={true}
                to='/customer_stories'
                children='See More'
              />
            </div>
          </Container>
        </div>
      }
    </Layout>
  )
}

export default StoryPage

export const pageQuery = graphql`
  query ($slug: String) {
    contentfulStory(slug: {eq: $slug}) {
      name
      metaDescription
      url
      caseStudyPdf
      openGraphImage {
        file {
          url
        }
      }
      heroBackground {
        gatsbyImageData(placeholder: BLURRED)
      }
      avatar {
        gatsbyImageData(placeholder: BLURRED, width: 140)
      }
      storyType
      video {
        videoId
        title
        poster {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      video2 {
        videoId
        title
        poster {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      challengeText {
        raw
      }
      solutionsText {
        raw
      }
      solutionsImage {
        gatsbyImageData(placeholder: BLURRED)
      }
      testimonial {
        title
        quote {
          childMdx {
            body
          }
        }
        name
        image {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      testimonial2 {
        title
        quote {
          childMdx {
            body
          }
        }
        name
        image {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      results {
        extraData
        title
        text {
          raw
        }
      }
      usage {
        text {
          raw
        }
      }
      usageImage {
        gatsbyImageData(placeholder: BLURRED)
      }
      overviewText {
        raw
      }
      relatedStories {
        slug
        storyType
        name
        blurb
        avatar {
          gatsbyImageData(width: 100, placeholder: BLURRED)
        }
      }
    }
  }
`
